import './index.scss'

$(document).scroll(function () { 
    var scroH = $(document).scrollTop(); 
    var viewH = $(window).height();       
    var contentH = $(document).height(); 
    if(scroH >100){  
        $('.header').addClass('nav-hidden')
    }else{
        $('.header').removeClass('nav-hidden')
    }  
    if (contentH - (scroH + viewH) <= 100){                 
    }           
    if (contentH = (scroH + viewH)){            
    }         
});

$('[data-open="cmenu"]').on('click', function () {
    if ($('.open-menu .hamburger').hasClass('active')) {
        $('.open-menu .hamburger').removeClass('active')
        $('.open-menu').removeClass('active')
        $('.mob-menu').removeClass('active')
    } else {
        $('.open-menu .hamburger').addClass('active')
        $('.open-menu').addClass('active')
        $('.mob-menu').addClass('active')
    }
})

// $(".header .pc-nav ul li").mouseenter(function () {
//     if (!$(this).hasClass('active')) {
//         $(this).addClass('hover');
//         $(this).siblings('.active').addClass('not-active');
//     }
// }).mouseleave(function () {
//     if (!$(this).hasClass('active')) {
//         $('.header .pc-nav ul li').removeClass('hover');
//         $('.header .pc-nav ul li.active').removeClass('not-active');
//     }
// });