module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<header>\r\n    <div class="header">\r\n        <div class="left-logo"><a href="#"><img src="' +
((__t = (require('./images/logo.png'))) == null ? '' : __t) +
'" alt=""></a></div>\r\n        <div class="right-nav">\r\n            <ul>\r\n                <li class="active"><a href="#">我们的公司</a></li>\r\n                <li><a href="#">我们的项目</a></li>\r\n                <li><a href="#">我们的服务</a></li>\r\n                <li><a href="#">我们的动态</a></li>\r\n                <li><a href="#">我们的公益</a></li>\r\n                <li><a href="#">联系我们</a></li>\r\n            </ul>\r\n            <a class="language" href="#">EN</a>\r\n        </div>\r\n    </div>\r\n    <div class="open-menu" data-open="cmenu">\r\n        <div class="hamburger hamburger-11">\r\n            <div></div>\r\n        </div>\r\n    </div>\r\n    <div class="mob-menu">\r\n        <a class="language" href="#">EN</a>\r\n        <ul>\r\n            <li><a href="#">我们的公司</a></li>\r\n            <li><a href="#">我们的项目</a></li>\r\n            <li><a href="#">我们的服务</a></li>\r\n            <li><a href="#">我们的动态</a></li>\r\n            <li><a href="#">我们的公益</a></li>\r\n            <li><a href="#">联系我们</a></li>\r\n        </ul>\r\n    </div>\r\n</header>';

}
return __p
}