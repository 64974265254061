import './index.scss'

$('.footer .back-top').on('click',function () {
    $("html, body").animate({
        scrollTop: 0 + "px"
    }, {
        duration: 500,
        easing: "swing"
    });
    return false;
})